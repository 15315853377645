<template>
    <div class="zykt-main p-1 h-100 box-border">
        <!-- 搜索栏 -->
        <el-card class="zy-card ova yes-head h-100">
            <el-row :gutter="10">
                <!-- 表单 -->
                <el-col :lg="10">
                    <el-card class="no-head zy-card mb-1">
                        <div class="d-flex ai-center py-1">
                            <zy-cascader
                                class="mr-1"
                                ref="zyCascader"
                                expand-trigger="click"
                                :cascaderData="cascaderData"
                                lazy
                                @lazy-load="handleCascaderLazyLoad"
                                @change="handleCascaderChange"
                            ></zy-cascader>
                            <el-input
                                class="mr-1"
                                v-model="room"
                                placeholder="户号"
                                style="width:120px"
                            ></el-input>
                            <el-button type="primary" @click="searchTabData">
                                搜索
                            </el-button>
                        </div>
                        <el-form ref="payForm" :model="payForm" :inline="true">
                            <div
                                class="font-16 global-head po-relative pl-1 mb-1"
                            >
                                充值信息
                            </div>
                            <el-form-item label="小区:" class="ml-1">
                                <el-input
                                    style="width:100px"
                                    placeholder="自动填充"
                                    v-model="payForm.acname"
                                    :disabled="true"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item label="楼栋:" class="ml-1">
                                <el-input
                                    style="width:100px"
                                    placeholder="自动填充"
                                    v-model="payForm.building"
                                    :disabled="true"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item label="单元:" class="ml-1">
                                <el-input
                                    style="width:100px"
                                    placeholder="自动填充"
                                    v-model="payForm.unit"
                                    :disabled="true"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item label="户号:" class="ml-1">
                                <el-input
                                    style="width:100px"
                                    placeholder="自动填充"
                                    v-model="payForm.room"
                                    :disabled="true"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item label="余额:" class="ml-1">
                                <el-input
                                    style="width:100px"
                                    placeholder="自动填充"
                                    v-model="payForm.totalMoney"
                                    :disabled="true"
                                >
                                </el-input>
                            </el-form-item>
                            <div
                                class="font-16 global-head po-relative pl-1 mb-1"
                            >
                                充值金额
                            </div>
                            <el-form-item class="ml-1">
                                <el-radio-group v-model="payForm.addMoney">
                                    <div class="d-flex mb-1">
                                        <el-radio label="100" border>
                                            充值100
                                        </el-radio>
                                        <el-radio label="200" border>
                                            充值200
                                        </el-radio>
                                        <el-radio label="300" border>
                                            充值300
                                        </el-radio>
                                    </div>
                                    <div class="d-flex">
                                        <el-radio label="400" border>
                                            充值400
                                        </el-radio>
                                        <el-radio label="500" border>
                                            充值500
                                        </el-radio>
                                        <el-radio label="600" border>
                                            充值600
                                        </el-radio>
                                    </div>
                                </el-radio-group>
                            </el-form-item>

                            <div
                                class="font-16 global-head po-relative pl-1 mb-1"
                            >
                                自定义充值金额
                            </div>
                            <el-form-item class="ml-1">
                                <el-input
                                    v-model="payForm.addMoney"
                                    placeholder="请输入充值金额"
                                    style="width:200px"
                                ></el-input>
                            </el-form-item>
                            <div
                                class="font-16 global-head po-relative pl-1 mb-1"
                            >
                                充值方式
                            </div>
                            <el-form-item class="ml-1">
                                <el-radio-group v-model="payForm.type">
                                    <el-radio label="微信" border></el-radio>
                                    <el-radio label="支付宝" border></el-radio>
                                    <el-radio label="现金" border></el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                        <el-button type="primary" @click="submit"
                            >确认充值</el-button
                        >
                    </el-card>
                </el-col>

                <!-- 表格 -->
                <el-col :lg="14">
                    <el-card class="zy-card no-head">
                        <div class="mb-1 d-flex">
                            <zy-cascader
                                class="mr-1"
                                ref="zyCascader"
                                expand-trigger="click"
                                :cascaderData="cascaderData"
                                lazy
                                @lazy-load="handleCascaderLazyLoad"
                                @change="handleCascaderChange"
                            ></zy-cascader>
                            <el-input
                                class="mr-1"
                                v-model="room"
                                placeholder="户号"
                                style="width:120px"
                            ></el-input>
                            <el-select
                                class="selectInp mr-1"
                                v-model="paySt"
                                placeholder="请选择缴费状态"
                            >
                                <el-option
                                    v-for="item in payStOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-button type="primary" @click="searchTab"
                                >搜索</el-button
                            >
                        </div>
                        <zy-table
                            :tbHead="tbHead"
                            :tbBody="tbBody"
                            height="480px"
                            highlightCurrentRow
                        >
                        </zy-table>
                        <zy-page
                            class="search-site-page mt-1"
                            @current-change="handlePageCurrentChange"
                            @size-change="handlePageSizeChange"
                            :pageModel="pageModel"
                            :total="total"
                            :pageSizes="[15, 20, 50, 300]"
                        ></zy-page>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import cascader from '@/mixins/select'
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
export default {
    components: { ZyTable },
    mixins: [ZyPage, cascader],
    data() {
        return {
            room: '',
            // 表单
            payForm: {},
            // 计费状态
            paySt: '',
            payStOpt: [
                {
                    value: '全部',
                    label: '全部'
                },
                {
                    value: '已缴费',
                    label: '已缴费'
                },
                {
                    value: '未缴费',
                    label: '未缴费'
                },
                {
                    value: '欠费',
                    label: '欠费'
                }
            ],
            // 表头
            tbHead: [
                // {
                //     prop: 'user_id',
                //     label: '序号',
                //     width: 100
                // },
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋',
                    width: 50
                },
                {
                    prop: 'unit',
                    label: '单元',
                    width: 50
                },
                {
                    prop: 'room',
                    label: '户号',
                    width: 50
                },
                {
                    prop: 'totalMoney',
                    label: '余额'
                },
                {
                    prop: 'money',
                    label: '充值金额'
                },
                {
                    prop: 'type',
                    label: '充值方式'
                },
                {
                    prop: 'operator',
                    label: '操作人',
                    width: 65
                },
                {
                    prop: 'recordTime',
                    label: '充值时间',
                    sortable: true,
                    width: 170
                }
            ],

            // 内容
            tbBody: []
        }
    },
    created() {
        this.getPayMsg()
    },
    methods: {
        // 搜索
        async searchTabData() {
            const par = this.cascaderModel
            par['room'] = this.room
            par['qianFeiStatus'] = this.paySt
            const res = await this.$api.selectData.searchTab(par)

            this.payForm = res.data.list[0]
            this.getPayMsg()
        },

        // 充值
        async submit() {
            this.$confirm('是否确认充值信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

                .then(async () => {
                    var strcookie = document.cookie //获取cookie字符串
                    var arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == name) {
                            return arr[1]
                        }
                    }
                    const deviceId = this.payForm.deviceId
                    const addMoney = this.payForm.addMoney
                    const type = this.payForm.type
                    const operator = arr[1]
                    const res = await this.$api.pay.getPay(
                        deviceId,
                        addMoney,
                        type,
                        operator
                    )
                    if (res.code != 200) {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.data
                        })
                    }

                    this.payForm = {}
                    this.getPayMsg()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消充值!'
                    })
                })
        },

        // 充值信息
        async getPayMsg() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.pay.getPayMsg(pageNum, pageSize)
            this.tbBody = res.data.list
            this.total = parseInt(res.msg)
        },

        // 搜索表格
        async searchTab() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const apartmentComplexName = this.cascaderModel.apartmentComplexName
            const building = this.cascaderModel.building
            const unit = this.cascaderModel.unit
            const room = this.room
            const startTime = ''
            const endTime = ''
            const qianFeiStatus = this.paySt
            const res = await this.$api.pay.getPayMsg(
                pageNum,
                pageSize,
                apartmentComplexName,
                building,
                unit,
                room,
                startTime,
                endTime,
                qianFeiStatus
            )
            this.tbBody = res.data.list
            this.total = parseInt(res.msg)
        },

        // 分页回调
        getPageList() {
            return this.getPayMsg()
        }
    }
}
</script>
<style lang="scss" scoped>
.el-radio {
    margin-right: 10px !important;
}
</style>
